import { Scene } from "phaser";
import Phaser from "phaser";

export default class Workspace extends Scene {
  constructor() {
    super({ key: "Workspace" });
  }

  preload() {
    //
  }
  init(data) {
    this.sceneName = data.sceneName
  }
  graphics;
  lineStyle = 2;
  strokeColor = 0x000000;
  circle;

  create() {
    this.rectangle = this.add.rectangle(240, 667, 480, 500, 0xffffff);
    // Define the animation properties
    // const tweenConfig = {
    //   targets: this.rectangle,
    //   y: 667, // Move upward to the desired position
    //   ease: 'Linear', // Use a linear ease function
    //   duration: 500, // Duration of the animation in milliseconds (1 second in this case)
    //   yoyo: false, // Don't reverse the animation
    //   repeat: 0, // Do not repeat the animation
    // };

    // Create the tween
    // this.tweens.add(tweenConfig);
    this.btnTrashBin = this.add
      .image(450, 670, "btnTrashBin")
      .setScale(0.9)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", this.clearCanvas, this);
      this.closeBtn = this.add.image(450, 460, "imgCloseBtn").setScale(0.3);
      this.closeBtn.on("pointerdown", this.clickClose, this);
      this.closeBtn.setInteractive({ useHandCursor: true });
    this.graphics = this.add.graphics();
    this.graphics.lineStyle(this.lineStyle, this.strokeColor);

    // Event listeners for pointer movement and touch
    this.input.on("pointermove", this.handlePointerMove, this);
    this.input.on("pointerdown", this.startDrawing, this);
    this.input.on("pointerup", this.stopDrawing, this);

    // UI controls
    this.createUIControls.call(this);
  }

  createUIControls() {
    // Add UI buttons for color and clear
    this.circle = this.add.circle(450, 610, 18, 0x000000);
    this.circle.setInteractive().on("pointerdown", this.changeColor, this);
    // this.add
    //   .text(10, 10, "Change Color", { fontSize: "16px", fill: "#fff" })
    //   .setInteractive()
    //   .on("pointerdown", this.changeColor, this);

    // this.add
    //   .text(10, 40, "Clear Canvas", { fontSize: "16px", fill: "#fff" })
    //   .setInteractive()
    //   .on("pointerdown", this.clearCanvas, this);
  }

  clearCanvas() {
    // Clear the canvas
    this.graphics.clear();
    this.graphics.lineStyle(this.lineStyle, this.strokeColor); // Restore line style
  }

  changeColor() {
    // Change drawing color randomly
    this.strokeColor = Phaser.Display.Color.RandomRGB().color;
    this.circle.setFillStyle(this.strokeColor);
    this.graphics.lineStyle(this.lineStyle, this.strokeColor);
  }

  stopDrawing() {
    // Stop drawing, if needed
  }

  startDrawing(pointer) {
    // Check if the pointer is within the allowed rectangle
    if (this.isPointerInsideRectangle(pointer)) {
      this.graphics.beginPath();
      this.graphics.moveTo(pointer.x, pointer.y);
    }
  }

  handlePointerMove(pointer) {
    // Check if the pointer is within the allowed rectangle before drawing
    if (pointer.isDown && this.isPointerInsideRectangle(pointer)) {
      this.graphics.lineTo(pointer.x, pointer.y);
      this.graphics.strokePath();
    }
  }

  isPointerInsideRectangle(pointer) {
    const rectX = 0;
    const rectY = 430;
    const rectWidth = 480;
    const rectHeight = 500;

    return (
      pointer.x >= rectX &&
      pointer.x <= rectX + rectWidth &&
      pointer.y >= rectY &&
      pointer.y <= rectY + rectHeight
    );
  }
  clickClose() {

    this.scene.stop("Workspace");
    this.scene.resume(this.sceneName);
  }
  update() {
    //
  }
}
