<script>
import { mapState } from "vuex";
const Workspace = require("@/games/Workspace/game");
export default {
  components: {
   
  },
  computed: mapState({
    event: (state) => state.event.data,
    skin: (state) => state.skin.data,
    hatData: (state) => state.apparel.data,
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
    series: (state) => state.series.data,
  }),
  props: [
    //
  ],
  data: () => ({
    startTime: null,
    endTime: null,
    env: process.env.VUE_APP_ENV,
    noOfRounds: 3,
    containerId: "game-container",
    gameInstance: null,
    bossRound: -1, // make it random
    master: 0,
    music: 0,
    sfx: 0,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    friendApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    questions: [],
    avatarItem: {
      shadow: null,
      back: null,
      right: null,
      body: null,
      outline: null,
      cheek: null,
      mouth: null,
      eye: null,
      hair: null,
      left: null,
      hat: null,
      color: null,
    },
    friendName: null,
    friendAvatarItem: {
      shadow: null,
      back: null,
      right: null,
      body: null,
      outline: null,
      cheek: null,
      mouth: null,
      eye: null,
      hair: null,
      left: null,
      hat: null,
      color: null,
    },
    avatarData: null,
    avatarBack: null,
    avatarRight: null,
    avatarLeft: null,
    avatarOutline: null,
    avatarMouth: null,
    avatarBody: null,
    avatarShadow: null,
    avatarEye: null,
    avatarHair: null,
    avatarCheek: null,
    avatarColor: null,
    avatarHat: null,
    mouth: [],
    body: [],
    outline: [],
    shadow: [],
    eye: [],
    hair: [],
    cheek: [],
    hat: [],
    left: [],
    right: [],
    back: [],
  }),
  
  created() {
   
  
        

  },
  mounted() {
    this.startGame();
  },
  beforeDestroy() {
    let master = this.settings.audio.master;
    let music = this.settings.audio.music;
    this.$bgMusic.src = require("@/assets/sounds/music_bg_1.mp3");
    this.$bgMusic.volume = music * master;
    this.$bgMusic.play();
  },
  destroyed() {
    if (this.gameInstance) {
      this.gameInstance.destroy(true);
      this.gameInstance = null;
    }
  },
  methods: {
    startGame() {
        this.gameInstance = Workspace.launch(this.containerId);
      
      this.gameInstance.scene.start("LoadingScene", {});
      this.$bgMusic.pause();
      this.$bgMusic.src = null;
    },
  
  },
};
</script>

<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="game-body">
         
          <div
            :id="containerId"
            style="max-width: 480px; padding: 0; max-height: 100vh !important"
          />
          <div
            v-if="!gameInstance"
            style="position: absolute"
            class="d-flex align-center justify-center flex-column"
          >
            <v-progress-linear
              color="brown darken-1"
              indeterminate
              height="10"
              style="max-width: 200px"
              class="my-5"
            ></v-progress-linear>
            <span class="brown--text py-2">
              {{ $t("string.game_loading_msg") }}</span
            >

            <v-btn
              depressed
              color="brown lighten-3"
              class="brown--text text--darken-3"
              @click="
                () => {
                  $router.go($router.currentRoute);
                }
              "
            >
              ↻ {{ $t("action.retry") }}
            </v-btn>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>

<style>
.game-body {
  max-width: 480px;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}
</style>