import { Scene } from "phaser";

export default class LandingScene extends Scene {
  constructor() {
    super({ key: "LandingScene" });
  }

  create() {
    // Load and set the background image
    this._backgroundImage = this.add
      .image(0, 0, "imgBackground")
      .setDisplaySize(480, 854)
      .setOrigin(0);

    // Create a draggable button
    this.penBtn = this.add
      .image(440, 580, "btnPen")
      .setScale(0.15)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", this.openWorkspace, this);

    // Set up event listeners for dragging
    // this.penBtn.on("drag", (pointer, dragX, dragY) => this.handleDrag(pointer, dragX, dragY), this);
    // this.penBtn.on("dragend", this.handleDragEnd, this);
  }

  handleDrag(pointer, dragX, dragY) {
    // Update the position of the button during dragging
    this.penBtn.x = dragX;
    this.penBtn.y = dragY;
  }

  handleDragEnd() {
    // Handle any logic when the drag ends (optional)
    console.log("Button dropped at:", this.penBtn.x, this.penBtn.y);
  }

 

  update() {
    // You can add update logic here if needed
  }

  openWorkspace() {
    this.scene.pause();
    this.scene.launch("Workspace", {
      sceneName: "LandingScene",
    });
  }
}
