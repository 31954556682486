import { Scene } from "phaser";

export default class LoadingScene extends Scene {
  constructor() {
    super({ key: "LoadingScene" });
  }

  init() {}

  preload() {
    var progressBar = this.add.graphics();
    var progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(100, 270, 280, 50);

    var width = this.cameras.main.width;
    var height = this.cameras.main.height;

    var loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: "Loading...",
      style: {
        font: "20px monospace",
        fill: "#000000",
      },
    });
    loadingText.setOrigin(0.5, 0.5);

    var percentText = this.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: "0%",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    percentText.setOrigin(0.5, 0.5);

    var assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: "",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    assetText.setOrigin(0.5, 0.5);

    this.load.on("progress", function (value) {
      percentText.setText(parseInt(value * 100) + "%");
      progressBar.clear();
      progressBar.fillStyle(0x000000, 1);
      progressBar.fillRect(110, 280, 260 * value, 30);
    });

    this.load.on("fileprogress", function (file) {
      assetText.setText("Loading asset: " + file.key);
    });
    this.load.on("complete", function () {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
      assetText.destroy();
    });
    // Loading logic END

    //*** load images ***//
    // general Images

    // buttons
    this.load.image("closeBtnImage", "/assets/common/btn-close.png");

    // panel bg

    // others

    // images specific to the game

    this.load.image(
      "imgBackground",
      "/assets/gameAssets/towerClimbing/background.png"
    );
    this.load.image("btnPen", "/assets/workspace/pen.png");
    this.load.image("btnTrashBin", "/assets/workspace/trash-bin-icon.png");
    this.load.image(
      "imgCloseBtn",
      "/assets/gameAssets/common/images/btn-close.png"
    );
  }

  create() {
    this.scene.start("LandingScene", {});

    // this.scene.start("SpecialLandingScene", {
    //   avatar: this.avatar,
    //   ladder: this.ladder,
    //   blueStar: this.blueStar,
    //   game: this.game,
    //   end: this.end,
    //   wizard: this.wizard,
    //   questions: this.questions,
    //   colors: this.colors,
    //   env: this.env,
    //   timestampReset:this.timestampReset,
    //   schoolYear: this.schoolYear,
    //   isHasSpecialItem:this.isHasSpecialItem
    // });
  }
}
